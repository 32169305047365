<template>
  <div>
    <div>
      <div v-if="!isMobile">
        <div ref="topheig">
          <div class="toimgbox" v-show="!t2show">
            <img :src="t1" alt="" />
            <div
              class="top-titles absolute axis-center pt65 text-shadow color-white"
            >
              <div class="fs36 width-full text-center hoverclass texts">
                尊重自然、顺应自然、保护自然
              </div>
              <div
                class="fs25 width-full pt50 text-center hoverclass flex flex-center align-items-center texts"
              >
                <!-- <div class="t-sons"></div> -->
                <div>开启人与自然和谐共生的新时代</div>
                <!-- <div class="t-sons"></div> -->
              </div>
            </div>
            <div class="but-left axis-x-center" @click="changeindexhend">
              <img
                class="axis-center"
                src="../../assets/images/newtest/t1_1.png"
                alt=""
              />
            </div>
          </div>

          <div class="toimgbox anmints" v-show="t2show">
            <!-- :poster="item.img ? item.img : ''" -->
            <div class="pt200"></div>
            <video
              loop
              ref="video8"
              id="video"
              controls
              :muted="true"
              preload="metadata"
              style="
                clear: both;
                display: block;
                margin: auto;
                width: 50%;
                height: 40%;
              "
              :src="v1"
              class=""
            ></video>
            <div class="box1200auto">
              <div
                class="color-white pl100 pt100 pr100 letter-5 lh-30"
                style="text-indent: 40px"
              >
                卓阳集团成立于2013年，致力于打造全球最具价值的清洁能源。卓阳集团以“创新绿色能源，成就智慧生活”为愿景，秉承“创新、绿色、协同、共享”
                的企业核心价值观，聚焦新能源产品研发、新能源电站开发投资、储能产品研发生产、新能源商业化应用等多个产业，矢志成为改变能源结构的全球化企业。
              </div>
            </div>
          </div>
        </div>
        <div v-for="(item, index) in videoList" :key="index">
          <videoindex
            :id="'ads' + index"
            :item="item"
            :ref="'deo' + index"
            :isones="index == 0 ? true : false"
            @video_runpage="video_runpage"
          ></videoindex>
        </div>
      </div>
      <div v-if="isMobile">
        <div ref="topheig">
          <div class="toimgbox" v-show="!t2show">
            <img :src="mt1" alt="" />
            <div
              class="top-titles1 absolute axis-x-center pt65 text-shadow color-white"
            >
              <div class="fs22 width-full text-center hoverclass letter-5">
                尊重自然、顺应自然、保护自然
              </div>
              <div class="fs16 pt30 width-full text-center hoverclass letter-5">
                开启人与自然和谐共生的新时代
              </div>
            </div>

            <div class="but-left axis-x-center" @click="changeindexhend">
              <img
                class="axis-center"
                src="../../assets/images/newtest/t1_1.png"
                alt=""
              />
            </div>
          </div>

          <div class="toimgbox anmints1" v-show="t2show">
            <!-- :poster="item.img ? item.img : ''" -->
            <img class="bgfill" :src="mt2" alt="" />
            <div class="pt100"></div>
            <video
              loop
              ref="video8"
              id="video"
              webkit-playsinline
              playsinline
              controls
              :muted="true"
              preload="metadata"
              style="
                clear: both;
                display: block;
                margin: auto;
                width: 90%;
                height: 25%;
              "
              :src="v1"
              class=""
            ></video>
            <div class="">
              <div
                class="color-white pl30 pt50 pr30 letter-3 fs16 lh15"
                style="text-indent: 40px"
              >
                卓阳集团成立于2013年，致力于打造全球最具价值的清洁能源。卓阳集团以“创新绿色能源，成就智慧生活”为愿景，秉承“创新、绿色、协同、共享”
                的企业核心价值观，聚焦新能源产品研发、新能源电站开发投资、储能产品研发生产、新能源商业化应用等多个产业，矢志成为改变能源结构的全球化企业。
              </div>
            </div>
            <div
              class="iconfont fs30 color-white icon-xiangxia axis-x-center bottom10 absolute"
            ></div>
          </div>
        </div>
        <div v-for="(item, index) in videoList" :key="index">
          <mobilenewtest
            :id="'ads' + index"
            :item="item"
            :ref="'deo' + index"
            :isones="index == 0 ? true : false"
            @video_runpage="video_runpage"
          ></mobilenewtest>
        </div>

        <!-- <div class="vh100 fs25" style="text-align: center; line-height: 80vh">
          请使用电脑浏览器查看！
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import p1 from "../../assets/images/newtest/p1.mp4";
import p2 from "../../assets/images/newtest/p2.jpg";
import p3 from "../../assets/images/newtest/p3.jpg";
import p4 from "../../assets/images/newtest/p4.mp4";
import img1 from "../../assets/images/newtest/listones.jpg";
import t1 from "../../assets/images/newtest/t1.jpg";
import t2 from "../../assets/images/newtest/t2.jpg";
import videoindex from "@/components/pc/videoindex";
// 手机端
import mt1 from "../../assets/images/newtest/mobile/mt1.jpg";
import mt2 from "../../assets/images/newtest/mobile/mt2.jpg";
import mp2 from "../../assets/images/newtest/mobile/mp2.png";
import mp3 from "../../assets/images/newtest/mobile/mp3.jpg";
import v1 from "../../assets/images/newtest/v1.mp4";
import mobilenewtest from "@/components/mobile/mobilenewtest";
import img5 from "../../assets/images/newtest/mobile/5.jpg";

import { look_mixin } from "@/utils/looktime.js";
import { mapGetters } from "vuex";
export default {
  components: {
    videoindex,
    mobilenewtest,
  },
  mixins: [look_mixin],
  computed: {
    ...mapGetters(["isMobile"]),
  },
  data() {
    return {
      scroll: 0,
      actives: 0,
      meout: null,
      t1: t1,
      t2: t2,
      v1: v1,
      mt1: mt1,
      mt2: mt2,
      mp2: mp2,
      mp3: mp3,
      t2show: false,
      videoList: [
        {
          id: 1,
          title: "光伏电站项目",
          desc: "推动能源结构的转变",
          src: p1,
          isvideo: true,
          pages: true,
          runurl: "/transtation",
          img: img1,
        },
        {
          id: 2,
          title: "储能产品",
          desc: "模块化设计，多场景应用",
          src: p2,
          isvideo: false,
          pages: false,
          runurl: "/pcsbox",
        },
        {
          id: 3,
          title: "清洁能源解决方案",
          desc: "科学设计，定制化服务",
          src: p3,
          isvideo: false,
          pages: false,
          runurl: "/programme",
        },
        {
          id: 4,
          title: "卓尔不群 阳煦山立",
          desc: "我们矢志成为改变能源结构的全球化企业",
          src: p4,
          isvideo: true,
          pages: false,
          runurl: "/about",
          img: img5,
        },
      ],
      video8player: null,
      positionList: [],
      styleObj: {
        width: "100vw",
        height: "100vh",
      },
    };
  },
  mounted() {
    if (this.isMobile) {
      this.videoList[1].src = this.mp2;
      this.videoList[2].src = this.mp3;
      // alert("请使用电脑浏览器查看！");
    }
    this.video8player = this.$refs.video8;
    // console.log(this.$refs.topheig, "当前页面的高度");

    setTimeout(() => {
      this.linetoscroll();
      this.$refs.deo0[0].videopause();
      this.$refs.deo1[0].videopause();
      this.$refs.deo3[0].videopause();

      this.videoList[0].pages = true;
      this.videoList[1].pages = true;
      this.videoList[2].pages = true;
      this.videoList[3].pages = true;
    }, 500);
    window.addEventListener("scroll", this.handleScroll);
  },
  //销毁,否则跳到别的路由还是会出现
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    video_runpage(e) {
      this.user_btn_click(e.title, e.runurl, e.btn_name);
    },
    // 改变首页的显隐
    changeindexhend() {
      this.t2show = true;
      this.video8player.play();
      this.$refs.deo0[0].videopause();
      this.$refs.deo1[0].videopause();
      this.$refs.deo3[0].videopause();
    },
    // 每轮基线的位置
    linetoscroll() {
      let list = [];
      for (let i = 0; i < this.videoList.length; i++) {
        let ps = this.$refs["deo" + i][0];
        list.push({ ids: i, top: this.soletop(ps) * i });
      }
      this.positionList = list;
    },
    //  返回当前组件距离顶部的距离
    soletop(comref) {
      return comref.$el.getBoundingClientRect().height;
    },

    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (
        this.scroll <
        this.$refs.topheig.getBoundingClientRect().height + 25
      ) {
        if (this.t2show) {
          this.video8player.play();
        }

        this.videoList[0].pages = false;
        this.videoList[1].pages = false;
        this.videoList[2].pages = false;
        this.videoList[3].pages = false;
      }
      // 第二个屏幕
      // console.log(this.positionList);
      if (
        this.scroll > this.$refs.topheig.getBoundingClientRect().height - 100 &&
        this.scroll <
          this.positionList[1].top +
            this.$refs.topheig.getBoundingClientRect().height -
            100
      ) {
        if (this.t2show) {
          this.video8player.pause();
        }

        this.$refs.deo0[0].videoplay();
        this.$refs.deo1[0].videopause();
        this.$refs.deo3[0].videopause();
        // setTimeout(() => {
        this.videoList[0].pages = true;
        this.videoList[1].pages = false;
        this.videoList[2].pages = false;
        this.videoList[3].pages = false;
        // }, 800);
        this.actives = 0;
      }
      // 第三个屏幕
      if (
        this.scroll <
          this.positionList[2].top +
            this.$refs.topheig.getBoundingClientRect().height -
            100 &&
        this.scroll >
          this.positionList[1].top +
            this.$refs.topheig.getBoundingClientRect().height -
            100
      ) {
        this.$refs.deo0[0].videopause();
        this.$refs.deo1[0].videoplay();
        this.$refs.deo3[0].videopause();
        this.videoList[0].pages = false;
        this.videoList[1].pages = true;
        this.videoList[2].pages = false;
        this.videoList[3].pages = false;
        this.actives = 1;
      }
      // // 第四个屏幕
      if (
        this.scroll >
          this.positionList[2].top +
            this.$refs.topheig.getBoundingClientRect().height -
            100 &&
        this.scroll <
          this.positionList[3].top +
            this.$refs.topheig.getBoundingClientRect().height -
            100
      ) {
        this.$refs.deo0[0].videopause();
        this.$refs.deo1[0].videopause();
        this.$refs.deo3[0].videopause();

        // setTimeout(() => {
        this.videoList[0].pages = false;
        this.videoList[1].pages = false;
        this.videoList[2].pages = true;
        this.videoList[3].pages = false;
        // }, 800);
        this.actives = 2;
      }
      // // 第五个屏幕
      if (
        this.scroll >
        this.positionList[3].top +
          this.$refs.topheig.getBoundingClientRect().height -
          100
      ) {
        this.$refs.deo0[0].videopause();
        this.$refs.deo1[0].videopause();
        this.$refs.deo3[0].videoplay();

        // setTimeout(() => {
        this.videoList[0].pages = false;
        this.videoList[1].pages = false;
        this.videoList[2].pages = false;
        this.videoList[3].pages = true;
        // }, 300);
        this.actives = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.anmints {
  background: url("../../assets/images/newtest/t2.jpg") no-repeat center center;
  background-size: cover;
}
// .anmints1 {
//   background: url("../../assets/images/newtest/mobile/mt2.jpg") no-repeat center
//     center;
//   background-size: cover;
// }
.bgfill {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@keyframes showup {
  from {
    letter-spacing: -50px;
    filter: blur(20px);
  }
  to {
    letter-spacing: 5px;
    filter: blur(0px);
  }
}
.toimgbox {
  width: 100%;
  height: 100vh;
  position: relative;
  video {
    display: block;
    object-fit: cover;
  }
  .top-titles {
    // top: 200px;
    width: 100%;
    filter: contrast(30);
    .texts {
      animation: showup 3s forwards;
      // letter-spacing: 5px;
    }
  }
  .top-titles1 {
    top: 20%;
    width: 100%;
    // letter-spacing: 5px;
  }
  .but-left {
    position: relative;
    bottom: 250px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    animation: load 1.5s infinite alternate;
    img {
      display: block;
      position: absolute;
      border-radius: 50% !important;
      object-fit: cover !important;
      width: 80px;
      height: 80px;
    }

    // overflow: hidden;
  }

  @keyframes load {
    0% {
      box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.5);
    }
    100% {
      box-shadow: 0px 0px 40px 0px rgba(255, 255, 255, 1);
    }
  }
  .t-sons {
    height: 1px;
    width: 50px;
    border: 1px solid #fff;
  }
  .t-sons1 {
    height: 1px;
    width: 20px;
    border: 1px solid #fff;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.banner-titles {
  top: 200px;
  width: 100%;
  // letter-spacing: 5px;
}
.imgbox {
  width: 100%;
  position: relative;
  .videosa {
    bottom: 150px;

    .more {
      background: #e8e9eb;
      //   padding: 10px 50px;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      margin-right: 100px;
    }
    .more1 {
      background: #e8e9eb;
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
    }
  }
  video {
    width: 100vw;
    height: 100vh;
    display: block;
    object-fit: cover;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
</style>